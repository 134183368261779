import React, { Component } from "react";
import { FormExtractor } from "@brianwhaley/pixelated-components";

export default class FormExtract extends Component {
	render() {
		return (
			<FormExtractor />
		);
	}
}
