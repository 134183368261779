import React, { Component } from "react";
import { FormBuilder } from "@brianwhaley/pixelated-components";

export default class FormBuild extends Component {
	constructor (props) {
		super(props);
		this.state = {
		};
	}
	render() {
		return (
			<FormBuilder />
		);
	}
}
